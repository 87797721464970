<template>
    <v-select  outlined v-model="card" :error-messages="error_messages"  :loading="loading" :disabled="loading" label="Cartão" :items="getCards" item-text="name" item-value="id" ></v-select>
</template>

<script>
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'
export default {

    components: {},
    props: {
        value: {},
        error_messages:{}
    },

    data: () => ({
        loading: false,
        card: '',
        cards:[],
    }),

    watch: {
        value(val) {
            this.card = val

        },
        card(){
          this.$emit('input',this.card)
          this.$emit('change',this.card)

          //proucura o objeto correspondente ao id card
          const tariffObject = this.cards.find(item =>{
            return item.id == this.card
          })


          this.$emit('object',tariffObject)
        }

    },

    created() {
        this.cards = this.getCards
        this.card = this.value
        // this.loading = true
        // axios
        //     .get('helper/get_room_categorys')
        //     .then(response => {
        //       this.roomCategorys = response.data
        //     })
        //     .finally(() => {
        //         this.loading = false
        //     })
    },
    computed: {
       ...mapGetters('select',['getCards'])
    },

    methods: {},
}
</script>
