<template>
<v-dialog v-model="value" max-width="500px">

    <v-card>
        <BarTitleDialog title="Cartão de Débito" @close="$emit('input', false)" />

        <v-card-text>
            <v-row>
                <v-col class="text-left text">
                    Valor a pagar
                </v-col>
                <v-col class="text-right text">
                    R$ {{ formatMoney(remainingValue) }}
                </v-col>
            </v-row>
            <!-- <v-row no-gutters>
                <v-col>
                    <SelectDebitCard :error_messages="errorCard" class="mb-2 mt-5" v-model="selectCard" @object="setObjectCard"></SelectDebitCard>
                </v-col>
            </v-row> -->
            <v-row no-gutters>
              <v-col>
                <v-text-field class="mt-5" v-model="codeOperation"  outlined label="Codigo da operação"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <vuetify-money :error-messages="validPayValue" v-model="payValue" outlined label="Valor pago pelo cliente" />
                </v-col>
            </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-row>
                <v-col class="text-right">
                    <v-btn text x-large @click="close()">Cancelar</v-btn>
                    <v-btn class="ml-2" color="primary" @click="addPayment()" x-large>Receber</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {
    mdiWindowClose,
} from '@mdi/js'
import {
    mapActions,
    mapGetters,
    mapMutations
} from 'vuex';

import BarTitleDialog from '@/components/BarTitleDialog.vue';
import SelectDebitCard from '@/components/SelectDebitCard.vue';
export default {
    props: {
        value: {
            type: Boolean
        }
    },
    components: {
        BarTitleDialog,
        SelectDebitCard
    },
    watch: {
        value(val) {
            if (val) {
                // this.generateValues()
                this.payValue = this.remainingValue
            }
        },
        pixKey() {
            this.errorPixKey = ''
        },
        objectCard(val){
          console.log(val)
        }
    },
    data: () => ({
        codeOperation:'',
        numberInstallment:[],
        selectNumberInstallment:null,
        objectCard:{},
        selectCard:null,
        errorCard:null,
        valueValid: [],
        payValue: 0,
        change: 0,
        loading: false,
        auxValues: [],
        data: {
            deposit: 0,
            obs: ''
        },

        icons: {
            mdiWindowClose,
        },
    }),

    created() {

    },
    computed: {
        ...mapGetters('checkout', ['getTotalToPay', 'remainingValue']),
        validPayValue() {

            if (this.payValue <= 0) {
                return this.valueValid = ['Quantia deve ser 0.01 ou superior']
            }

            return []
        },
        labelChange() {

            if (this.change <= 0) {
                return 'Sem troco'
            }
            return 'Troco: ' + this.formatMoney(this.change)
        },



    },
    methods: {
        ...mapMutations('checkout', ['addPaymentMethod', 'setStage']),
        setObjectCard(card){

          if (card.operation_type == 'credito' || card.operation_type == 'debito_credito'){
            let installment = []
            for (let i=1;i <= card.number_installment;i++){
               installment.push({text:i,value:i})
            }
            this.numberInstallment = installment

          }



        },
        applyValue(val) {
            this.payValue = val
            //this.change = val - this.getTotal
        },
        close() {
            this.$emit('input', false)
        },

        addPayment() {

            // if (this.selectCard == undefined || this.selectCard == null) {
            //      this.errorCard = 'Cartão é obrigatório'
            //      return false

            // }else
            if (this.payValue <= 0) {
                 this.valueValid = 'Quantia deve ser 0.01 ou superior'
                 return false
            }

            const paymentMethod = {
                // card_id: this.selectCard,
                method: 'debit_card',
                value: this.payValue,
                code_operation:this.codeOperation,
            }

            this.addPaymentMethod(paymentMethod)
            this.close()

            if (this.remainingValue > 0) { // se existe valor restante vai para a pagina de mutiplas formas de pagamento
                this.setStage('multiple_payment_methods')
            }

        },
        formatMoney(value) {
            if (value != null) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
        },
    }

}
</script>

<style scoped>
.text {
    color: black;
    font-size: large;
}

.card {
    height: 67px;
    cursor: pointer;
    border: solid 1px black;
}

.change {
    color: blueviolet;
    font-size: medium;
}

.card:hover {
    background: rgb(248, 248, 248);
}
</style>
