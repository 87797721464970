
<template>
<div>
    <v-row>
        <v-col cols='1'>
            <v-btn outlined icon x-large @click="back()">
                <v-icon> {{ icons.mdiChevronLeft }}</v-icon>
            </v-btn>
        </v-col>
        <v-col>
            <span class="title">
                <h2> Selecione o pagamento </h2>
            </span>
        </v-col>

    </v-row>
    <v-row>
        <v-col cols="4">
            <v-card class="card" outlined elevation="3" @click="showModalPaymentMoney()">
                <v-card-text class="pt-4">
                    <div class="text-center text_card">
                        <v-icon color="success" x-large> {{ icons.mdiCashUsd }}</v-icon>
                    </div>
                    <div class="text-center text_card">Dinheiro</div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card class="card" outlined elevation="3" @click="showModalPaymentCard()">
                <v-card-text class="pt-4">
                    <div class="text-center text_card">
                        <v-icon color="primary" x-large> {{ icons.mdiCreditCard }}</v-icon>
                    </div>
                    <div class="text-center text_card">Cartão de Crédito</div>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="4">
            <v-card class="card" outlined elevation="3" @click="showModalPaymentDebitCard()">
                <v-card-text class="pt-4">
                    <div class="text-center text_card">
                        <v-icon color="success" x-large> {{ icons.mdiCreditCard }}</v-icon>
                    </div>
                    <div class="text-center text_card">Cartão de Débito</div>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="4">
            <v-card class="card" outlined elevation="3" @click="showModalPaymentPix()" >
                <v-card-text class="pt-4">
                    <div class="text-center text_card">
                        <img width="40" src="@/assets/images/logos/logo-pix.png" alt="">
                    </div>
                    <div class="text-center text_card">Pix</div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card class="card" outlined elevation="3" @click="showMultiplePaymentMethods()">
                <v-card-text class="pt-4">
                    <div class="text-center text_card">
                        <v-icon color="error" x-large> {{ icons.mdiCashMultiple }}</v-icon>
                    </div>
                    <div class="text-center text_card">Mutiplas Formas</div>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="4">
            <v-card class="card" outlined elevation="3" @click="separatePayment()">
                <v-card-text class="pt-4">
                    <div class="text-center text_card">
                        <v-icon color="primary" x-large> {{ icons.mdiDivisionBox }}</v-icon>
                    </div>
                    <div class="text-center text_card">Separar Pagamentos</div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card class="card" outlined elevation="3" @click="showModalPaymentB2B()">
                <v-card-text class="pt-4">
                    <div class="text-center text_card">
                        <v-icon color="warning" x-large> {{ icons.mdiHandshakeOutline  }}</v-icon>
                    </div>
                    <div class="text-center text_card">B2B</div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card class="card" outlined elevation="3" @click="showModalPaymentInvoice()">
                <v-card-text class="pt-4">
                    <div class="text-center text_card">
                        <v-icon color="primary" x-large> {{ icons.mdiCalendarClock  }}</v-icon>
                    </div>
                    <div class="text-center text_card">Faturamento</div>
                </v-card-text>
            </v-card>
        </v-col>
        <!-- <v-col cols="4">
            <v-card class="card" outlined elevation="3" @click="showModalPaymentInvoice()">
                <v-card-text class="pt-4">
                    <div class="text-center text_card">
                        <v-icon color="warning" x-large> {{ icons.mdiHandshakeOutline  }}</v-icon>
                    </div>
                    <div class="text-center text_card">Adiantamento</div>
                </v-card-text>
            </v-card>
        </v-col> -->
        <!-- <v-col cols="4">
            <v-card class="card" outlined elevation="3" @click="addPaymentOther()">
                <v-card-text class="pt-4">
                    <div class="text-center text_card">
                        <v-icon color="warning" x-large> {{ icons.mdiCurrencyUsd }}</v-icon>
                    </div>
                    <div class="text-center text_card">Outros</div>
                </v-card-text>
            </v-card>
        </v-col> -->

    </v-row>
    <ModalPaymentMoney v-model="modalPaymentMoney" />
    <ModalPaymentPix v-model="modalPaymentPix" />
    <ModalPaymentCard v-model="modalPaymentCard"/>
    <ModalPaymentDebitCard v-model="modalPaymentDebitCard"/>
    <ModalPaymentInvoice v-model="modalPaymentInvoice"/>
    <ModalPaymentB2B v-model="modalPaymentB2B"/>
</div>
</template>

<script>
import ModalPaymentMoney from './ModalPaymentMoney.vue'
import ModalPaymentPix from './ModalPaymentPix.vue'
import ModalPaymentCard from './ModalPaymentCreditCard.vue'
import ModalPaymentDebitCard from './ModalPaymentDebitCard.vue'
import ModalPaymentInvoice from './ModalPaymentInvoice.vue'
import ModalPaymentB2B from './ModalPaymentB2B.vue'

import {
  mdiCog,
  mdiChevronLeft,
  mdiCashUsd,
  mdiCreditCard,
  mdiCashMultiple,
  mdiCurrencyUsd,
  mdiDivisionBox,
  mdiHandshakeOutline,
  mdiCalendarClock,
} from '@mdi/js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    ModalPaymentMoney,
    ModalPaymentPix,
    ModalPaymentCard,
    ModalPaymentDebitCard,
    ModalPaymentInvoice,
    ModalPaymentB2B,
  },
  data() {
    return {
      tabs: null,

      modalPaymentMoney: false,
      modalPaymentPix: false,
      modalPaymentCard: false,
      modalPaymentDebitCard: false,
      modalPaymentInvoice: false,
      modalPaymentB2B: false,

      icons: {
        mdiChevronLeft,
        mdiCog,
        mdiCashUsd,
        mdiCreditCard,
        mdiCashMultiple,
        mdiCurrencyUsd,
        mdiDivisionBox,
        mdiHandshakeOutline,
        mdiCalendarClock,
      },
    }
  },
  created() {
    this.loadPix()
    //   this.loadCreditCard()
    //   this.loadDebitCard()
  },
  computed: {
    ...mapGetters('checkout', ['getTotalToPay']),
  },
  methods: {
    ...mapMutations('checkout', ['setStage', 'addPaymentMethod', 'clearResponsible', 'setMethodPayment']),
    ...mapActions('select', ['loadPix', 'loadCreditCard', 'loadDebitCard']),

    showModalPaymentDebitCard() {
      this.modalPaymentDebitCard = true
    },
    showModalPaymentCard() {
      this.modalPaymentCard = true
    },
    showModalPaymentMoney() {
      this.modalPaymentMoney = true
    },
    showModalPaymentPix() {
      this.modalPaymentPix = true
    },
    showModalPaymentInvoice() {
      this.modalPaymentInvoice = true
    },
    showModalPaymentB2B() {
      this.modalPaymentB2B = true
    },
    showMultiplePaymentMethods() {
      this.setStage('multiple_payment_methods')
    },
    back() {
        window.history.back()
      // this.$router.replace('/manager_room')
    },
    addPaymentOther() {
      const paymentMethod = {
        method: 'other',
        value: this.getTotalToPay,
        clearPayments: true,
      }

      this.addPaymentMethod(paymentMethod)
    },
    separatePayment() {
      this.clearResponsible() //limpa os reponsaveis pelo pagamento
      this.setMethodPayment() //limpa os methodo de pagamentos
      this.setStage('separate_payment')
    },
  },
}
</script>

<style scoped>
.card {
  height: 100px;
  cursor: pointer;
}

.card:hover {
  background: rgb(248, 248, 248);
}

.text_card {
  color: black;
  font-size: 16px;
}

.title {
  color: black;
  font-size: 25px;
}
</style>
