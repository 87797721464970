<template>
<v-dialog v-model="dialog" max-width="500px">
    <v-card>
        <BarTitleDialog title="Pagamento Faturado" @close="dialog =false"></BarTitleDialog>
        <v-card-text>
            <v-row>
                <v-col class="text-left text">
                    Valor a pagar
                </v-col>
                <v-col class="text-right text"> R$  {{formatMoney(remainingValue)}}</v-col>
            </v-row>
            <v-row>
                <v-col>
                    <vuetify-money :error-messages="validPayValue" v-model="payValue" outlined label="Valor pago pelo cliente" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                  <InputDate :dense="false" :label="'Data Faturamento'" v-model="dateInvoice" ></InputDate>
                </v-col>
            </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-row>
                <v-col class="text-right">
                    <v-btn text large @click="dialog = false">Cancelar</v-btn>
                    <v-btn @click="addPayment()" class="ml-2" color="primary" x-large :disabled="validPayValue">Receber dinheiro</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import { mdiWindowClose } from '@mdi/js'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import BarTitleDialog from '@/components/BarTitleDialog.vue'
import InputDate from '@/components/InputDate.vue'

export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  components: {
    BarTitleDialog,
    InputDate,
  },
  watch: {
    value(val) {
      this.dialog = val
      if (val) {
        this.payValue = this.remainingValue
      }
    },
    dialog(val) {
      this.$emit('input', val)
    },
  },
  data: () => ({
    dateInvoice: '',
    payValue: 0,
    dialog: false,

    change: 0,
    loading: false,
    auxValues: [],
    data: {
      deposit: 0,
      obs: '',
    },

    icons: {
      mdiWindowClose,
    },
  }),

  created() {},
  computed: {
    ...mapGetters('checkout', ['getTotalToPay', 'remainingValue']),
    validPayValue() {
      if (this.payValue <= 0) {
        return (this.valueValid = ['Quantia deve ser 0.01 ou superior'])
      }

      return false
    },
  },
  methods: {
    ...mapMutations('checkout', ['addPaymentMethodSeparate']),

    applyValue(val) {
      this.payValue = val
    },
    close() {
      this.dialog = false
    },

    addPayment() {
      const paymentMethod = {
        method: 'invoice',
        value: this.payValue,
        date_invoice: this.dateInvoice,
      }

      this.addPaymentMethodSeparate(paymentMethod)
      this.close()
    },
    formatMoney(value) {
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
  },
}
</script>

<style scoped>
.text {
  color: black;
  font-size: large;
}

.card {
  height: 67px;
  cursor: pointer;
  border: solid 1px black;
}

.change {
  color: blueviolet;
  font-size: medium;
}

.card:hover {
  background: rgb(248, 248, 248);
}
</style>
