<template>
<v-select  outlined v-model="card" @change="setCard()" :error-messages="error_messages" :loading="loading" :disabled="loading" label="Cartão" :items="getDebitCards" item-text="name" item-value="id"></v-select>
</template>

<script>
import axios from '@/plugins/axios'
import {
    mapGetters
} from 'vuex'
export default {

    components: {},
    props: {
        value: {},
        error_messages: {}
    },

    data: () => ({
        loading: false,
        card: '',
    }),

    watch: {
        value(val) {
            this.card = val
        },

    },

    created() {
         this.card = this.value

    },
    computed: {
        ...mapGetters('select', ['getDebitCards'])
    },

    methods: {
        setCard() {
            this.$emit('input', this.card)
            this.$emit('change', this.card)

            //proucura o objeto correspondente ao id card
            const object = this.getDebitCards.find(item => {
                return item.id == this.card
            })

            this.$emit('object', object)
        }

    },
}
</script>
